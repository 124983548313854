import { useLayoutEffect, useState } from 'react'

export type WindowSize = {
  width: number
  height: number
  break: string
}

const useWindowSize = (): WindowSize => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
    break: 'xs'
  })

  useLayoutEffect(() => {
    function updateSize() {
      setSize(
        {
          width: window.innerWidth,
          height: window.innerHeight,
          break: breakpoint(window.innerWidth)
        }
      )
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const breakpoint = (width: number) => {
    if(width < 576) return 'xs'
    if(width < 768) return 'sm'
    if(width < 992) return 'md'
    if(width < 1200) return 'lg'
    if(width < 1400) return 'xl'
    return 'xxl'
  }

  return size
}

export default useWindowSize
