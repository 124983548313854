import React, { FunctionComponent } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'
import weekday from 'dayjs/plugin/weekday'
import isoWeek from 'dayjs/plugin/isoWeek'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TimesheetAuthority from './TimesheetAuthority'
import TimesheetFooter from './TimesheetFooter'

const theme = createTheme({
  typography: {
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        h1 {
          color: #333;
        }
      `,
    },
  },
})

dayjs.extend(objectSupport)
dayjs.extend(weekday)
dayjs.extend(isoWeek)
dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.updateLocale('en', {
    weekStart: 1,
})
dayjs.extend(isBetweenPlugin)

interface DailyData {
  id: number,
  day: string,
  date: Dayjs,
  startTime: Dayjs | null,
  endTime: Dayjs | null,
  breaks: Dayjs | null,
  total: Dayjs,
  notes: string | null,
}

type Timesheet = {
  id: number,
  data: DailyData[]
  classification: string
  grandTotal: string
  firstName: string
  lastName: string
  supervisor: string
  location: string
  client: string
  createdAt: string
  purchaseOrder: string
}
type Props = {
  timesheet: Timesheet
}

const parseTime = (time: string): Dayjs | null => {
  if (dayjs(time, 'hh:mm').isValid()) {
    return dayjs(time, 'hh:mm')
  }
  return null
}

const toTime = (time: string): string => parseTime(time)?.format('h:mma') || '-'
const toDuration = (time: string): string => {
  if(time === '00:00') return '-'
  const hrAndMin = parseTime(time)
  if (!hrAndMin) return '-'
  if(hrAndMin?.minute() === 0) return `${hrAndMin?.hour()}hr`
  return `${hrAndMin?.hour()}hr, ${hrAndMin?.minute()}min`
}

const toDailyDataRow = (data: any, index: number): any => {
  return {
    id: index,
    day: dayjs(data.date, 'DD/MM/YYYY').format('dddd'),
    date: data.date,
    startTime: toTime(data.startTime),
    endTime: toTime(data.endTime),
    breaks: toDuration(data.break),
    total: toDuration(data.total),
    notes: data.notes
    // notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut a"
  }
}

const grandTotalRow = (grandTotal: string): any => {
  return {
    id: 8,
    breaks: 'Total',
    total: `${grandTotal.split(':')[0]}hr, ${grandTotal.split(':')[1]}min`,
    // total: "288hr, 59min"
  }
}

const Timesheet: FunctionComponent<Props> = ({ timesheet }: Props): JSX.Element => {
  const rows = timesheet.data.map((d, index) => toDailyDataRow(d, index))
  rows.push(grandTotalRow(timesheet.grandTotal))

  const detailsInputProps = {
    readOnly: true,
  }

  const fullName = `${timesheet.firstName} ${timesheet.lastName}`

  const cellSX = {
    padding: 0,
    fontSize: 12,
    height: 40,
    border: '1px solid #999',
  }
  const TSTableCell = ({ children, sx, ...other }: any) => <TableCell sx={{...cellSX, ...sx}} {...other}>{children}</TableCell>

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="timesheet-container">
          <h1 className="text-center text-decoration-underline py-2 fw-bold fs-2">TIMESHEET</h1>
          <Grid container rowSpacing={2} spacing={2}>
            <Grid xs={6}>
              <TextField label="Employee Name"fullWidth  variant="standard" value={fullName} InputProps={detailsInputProps} />
            </Grid>
            <Grid xs={6}>
              <TextField label="Time Submitted" fullWidth variant="standard" value={timesheet.createdAt} InputProps={detailsInputProps} />
            </Grid>
            <Grid xs={6}>
              <TextField label="Classification/Job Title" fullWidth variant="standard" value={timesheet.classification} InputProps={detailsInputProps} />
            </Grid>
            <Grid xs={6}>
              <TextField label="Purchase Order" fullWidth variant="standard" value={timesheet.purchaseOrder || ' '} InputProps={detailsInputProps} />
            </Grid>
            <Grid xs={6}>
              <TextField label="Client/Company" fullWidth variant="standard" value={timesheet.client} InputProps={detailsInputProps} />
            </Grid>
            <Grid xs={6}>
              <TextField label="Location" fullWidth variant="standard" value={timesheet.location} InputProps={detailsInputProps} />
            </Grid>

            <Grid my={1} xs={12} style={{ height: 'auto' }}>
              <Table sx={{ }} aria-label="timesheet table">
                <TableHead>
                  <TableRow>
                    <TSTableCell align="center" sx={{ width: '80px' }}>Day</TSTableCell>
                    <TSTableCell align="center" sx={{ width: '100px' }}>Date</TSTableCell>
                    <TSTableCell align="center" sx={{ width: '80px' }}>Start Time</TSTableCell>
                    <TSTableCell align="center" sx={{ width: '80px' }}>End Time</TSTableCell>
                    <TSTableCell align="center" sx={{ width: '80px' }}>Breaks</TSTableCell>
                    <TSTableCell align="center" sx={{ width: '90px' }}>Total</TSTableCell>
                    <TSTableCell align="center">Notes</TSTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { padding: '14px 2px', fontWeight: 'bold', fontSize: '13px' }
                      }}
                    >
                      <TSTableCell align="left" sx={{ padding: '0 8px' }} component="th" scope="row">{row.day}</TSTableCell>
                      <TSTableCell align="center">{row.date}</TSTableCell>
                      <TSTableCell align="center">{row.startTime}</TSTableCell>
                      <TSTableCell align="center">{row.endTime}</TSTableCell>
                      <TSTableCell align="center">{row.breaks}</TSTableCell>
                      <TSTableCell align="center">{row.total}</TSTableCell>
                      <TSTableCell align="justify" sx={{ padding: '8px' }}>{row.notes}</TSTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Box component='section' sx={{ px: 2, my: 2, border: '0px solid rgba(0,0,0,0.25)', borderRadius: '4px' }}>
              <TimesheetAuthority />
              <Grid container rowSpacing={2} spacing={2} sx={{ my: 2 }}>
                <Grid xs={6}>
                  <TextField label="Employee Signature" fullWidth variant="standard" value={' '} InputProps={detailsInputProps} />
                </Grid>
                <Grid xs={6}>
                  <TextField label="Date" fullWidth variant="standard" value={' '} InputProps={detailsInputProps} />
                </Grid>
                <Grid xs={6}>
                  <TextField label="Supervisor/Agent Signature" fullWidth variant="standard" value={' '} InputProps={detailsInputProps} />
                </Grid>
                <Grid xs={6}>
                  <TextField label="Supervisor/Agent Name" fullWidth variant="standard" value={timesheet.supervisor} InputProps={detailsInputProps} />
                </Grid>
              </Grid>
            </Box>
            <Box component='section' sx={{ position: 'fixed', bottom: 10, width: '100%', display: 'flex' }}>
              <TimesheetFooter />
            </Box>
          </Grid>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default Timesheet