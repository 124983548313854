import React, { FunctionComponent, useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useForm } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'
import weekday from 'dayjs/plugin/weekday'
import isoWeek from 'dayjs/plugin/isoWeek'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import axios from 'axios'
import TimesheetTable from './TimesheetTable'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import TimesheetSuccessDialog from './TimesheetSuccessDialog'
import Alert from '@mui/material/Alert'
import TimesheetInstructions from './TimesheetInstructions'
import TimesheetConfirmDialog from './TimesheetConfirmDialog'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'

dayjs.extend(objectSupport)
dayjs.extend(weekday)
dayjs.extend(isoWeek)
dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.updateLocale('en', {
    weekStart: 1,
})
dayjs.extend(isBetweenPlugin)
const dateFormat = 'DD/MM/YYYY'

interface FormData {
  firstName: string,
  lastName: string,
  classification: string,
  client: string,
  supervisor: string,
  location: string,
  purchaseOrder: string,
}
export interface DailyData {
  id: number,
  day: string,
  date: Dayjs,
  start: Dayjs | null,
  end: Dayjs | null,
  breaks: Dayjs | null,
  total: Dayjs,
  notes: string | null,
}

export interface SuccessResponse {
  message: string,
  url: string,
}

const currentMonday = dayjs().weekday(0)
const initialData: DailyData[] = Array.from({ length: 7 }, (_, i) => {
  const date = currentMonday.weekday(currentMonday.weekday() + i)
  return {
    id: i,
    day: date.format('dddd'),
    date,
    start: null,
    end: null,
    breaks: null,
    total: dayjs({ hour:0, minute:0 }),
    notes: null

    // fixture data
    // start: dayjs({ hour:9, minute:0 }),
    // end: dayjs({ hour:21, minute:0 }),
    // breaks: dayjs({ hour:1, minute:30 }),
    // total: dayjs({ hour:10, minute:30 }),
    // notes: "Sed ut perspiciatis unde omnis iste natus "//error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dictass"
  }
})
const initialDetails = {
  firstName: 'Ashley',
  lastName: 'Ramsey',
  classification: 'Welder',
  client: 'Crane Corp FMG Solomon',
  supervisor: 'George Castanza',
  location: 'Crane Corp FMG Solomon',
  purchaseOrder: 'PO1234',
}

type Props = {
  csrfToken: string,
  recaptchaKey: string,
}

export type GrandTotal = {
  hours: number,
  minutes: number,
}

export type DeliveryOption = {
  downloadOrEmail: string,
  email: string,
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
})

const TimesheetForm: FunctionComponent<Props> = ({ csrfToken, recaptchaKey }: Props): JSX.Element => {
  const [confirm, setConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<SuccessResponse | null>(null)
  const [error, setError] = useState<string | null>(null)
  const recaptcha_action = 'upload_timesheet'
  const [formData, setFormData] = useState<FormData | null>()
  const [data, setData] = useState<DailyData[]>(initialData) // timesheet data
  const [grandTotal, setGrandTotal] = useState<GrandTotal>({ hours: 0, minutes :0 })
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deliveryOption, setDeliveryOption] = useState<DeliveryOption | null>(null)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' }) //, defaultValues: initialDetails })

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null)
      }, 5000)
      return () => clearTimeout(timer)
    }
    return
  }, [error])

  useEffect(() => {
    if (success) {
      setDialogOpen(true)
    }
    return () => setDialogOpen(false)
  }, [success])

  const confirmSubmit = (data: any) => {
    setFormData(data)
    setConfirm(true)
  }

  useEffect(() => {
    if (deliveryOption) {
      submitFormWithRecaptcha()
    }
  }, [deliveryOption])

  const grecaptchaObject = window.grecaptcha
  const submitFormWithRecaptcha = () => {
    setLoading(true)
    setError(null)
    setSuccess(null)

    grecaptchaObject.enterprise.ready(() => {
      grecaptchaObject.enterprise
        .execute(recaptchaKey, { action: recaptcha_action })
        .then((recaptchaToken: string) => {
          submitForm(recaptchaToken)
        })
    })
  }

  const parseData = (data: DailyData[]): any => {
    return data.map((day: DailyData) => {
      return {
        date: day.date.format(dateFormat),
        startTime: day.start?.format('HH:mm'),
        endTime: day.end?.format('HH:mm'),
        break: day.breaks?.format('HH:mm'),
        notes: day.notes,
        total: day.total.format('HH:mm'),
      }
    })
  }

  const submitForm = async (recaptchaToken: string) => {
    const url = '/timesheets'
    const payload = {
      timesheet: {
        ...formData,
        email: deliveryOption?.downloadOrEmail === 'email' ? deliveryOption.email : null,
        data: parseData(data),
        grandTotal: `${grandTotal.hours}:${grandTotal.minutes}`
      },
      recaptcha_token: recaptchaToken,
      recaptcha_action,
    }
    const config = {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    }

    axios.post(url, payload, config)
      .then((response) => {
        setLoading(false)
        setSuccess(response.data)
        reset()
        setData(initialData)
      })
      .catch((error) => {
        console.log({error})
        setLoading(false)
        setSuccess(null)
        setError(error.response.data.error)
      })
  }

  const submitButton = (): JSX.Element => {
    return (
      <>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<SendIcon />}
          variant="outlined"
          className="float-end"
          type="submit"
          size="large"
        >
          Submit
        </LoadingButton>
        <br />
        <br />
        {error && <Alert severity="error">{error}</Alert>}
      </>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Form id="timesheet" onSubmit={handleSubmit(confirmSubmit)} method="POST" noValidate>
          <TimesheetInstructions />
          <Row className="mb-md-3 justify-content-center">
            <Form.Group as={Col} md={6} sm={12} className="mb-md-0 mb-3" controlId="formGridFirstName">
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                placeholder="First Name"
                fullWidth
                required
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                {...register('firstName', {
                  required: { value: true, message: 'Required' },
                  maxLength: {
                    value: 30,
                    message: 'Please use 30 characters or less'
                  }
                })}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} className="mb-md-0 mb-3" controlId="formGridLastName">
              <TextField
                  id="lastName"
                  label="Last Name"
                  variant="outlined"
                  placeholder="Last Name"
                  fullWidth
                  required
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  {...register('lastName', {
                    required: { value: true, message: 'Required' },
                    maxLength: {
                      value: 30,
                      message: 'Please use 30 characters or less'
                    }
                  })}
                />
            </Form.Group>
          </Row>
          <Row className="mb-md-3">
            <Form.Group as={Col} md={6} sm={12} className="mb-md-0 mb-3" controlId="formGridClassification">
              <TextField
                id="classification"
                label="Classification/Job Title"
                variant="outlined"
                placeholder="Job Title"
                fullWidth
                required
                error={!!errors.classification}
                helperText={errors.classification?.message}
                {...register('classification', {
                  required: { value: true, message: 'Required' },
                  maxLength: {
                    value: 100,
                    message: 'Please use 100 characters or less'
                  }
                })}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} className="mb-md-0 mb-3" controlId="formGridPurchaseOrder">
              <TextField
                id="purchaseOrder"
                label="Purchase Order"
                variant="outlined"
                placeholder="Purchase Order"
                fullWidth
                error={!!errors.purchaseOrder}
                helperText={errors.purchaseOrder?.message}
                {...register('purchaseOrder', {
                  maxLength: {
                    value: 100,
                    message: 'Please use 100 characters or less'
                  }
                })}
              />
            </Form.Group>
          </Row>
          <Row className="mb-md-3">
            <Form.Group as={Col} md={6} sm={12} className="mb-md-0 mb-3" controlId="formGridClient">
              <TextField
                id="client"
                label="Client/Company"
                variant="outlined"
                placeholder="Client/Company"
                fullWidth
                required
                error={!!errors.client}
                helperText={errors.client?.message}
                {...register('client', {
                  required: { value: true, message: 'Required' },
                  maxLength: {
                    value: 100,
                    message: 'Please use 100 characters or less'
                  }
                })}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} sm={12} className="mb-md-0 mb-3" controlId="formGridSupervisor">
              <TextField
                id="supervisor"
                label="Supervisor/Agent Name"
                variant="outlined"
                placeholder="Supervisor"
                fullWidth
                required
                error={!!errors.supervisor}
                helperText={errors.supervisor?.message}
                {...register('supervisor', {
                  required: { value: true, message: 'Required' },
                  maxLength: {
                    value: 100,
                    message: 'Please use 100 characters or less'
                  }
                })}
              />
            </Form.Group>
          </Row>

          <Row className="mb-md-3">
            <Form.Group as={Col} sm={12} className="mb-md-0 mb-3" controlId="formGridLocation">
              <TextField
                id="location"
                label="Location"
                variant="outlined"
                placeholder="Location"
                fullWidth
                required
                error={!!errors.location}
                helperText={errors.location?.message}
                {...register('location', {
                  required: { value: true, message: 'Required' },
                  maxLength: {
                    value: 100,
                    message: 'Please use 100 characters or less'
                  }
                })}
              />
            </Form.Group>
          </Row>

          <Row className="py-3 justify-content-center timesheet-table">
            <TimesheetTable
              data={data}
              setData={setData}
              grandTotal={grandTotal}
              setGrandTotal={setGrandTotal}
            />
          </Row>
          <div className="col-12 mt-4">
            {submitButton()}
          </div>
        </Form>
        {confirm && <TimesheetConfirmDialog open={confirm} setOpen={setConfirm} setDeliveryOption={setDeliveryOption} />}
        {success && <TimesheetSuccessDialog open={dialogOpen} setOpen={setDialogOpen} success={success} />}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default TimesheetForm