import React, { Fragment, FunctionComponent, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import SendIcon from '@mui/icons-material/Send'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Form from 'react-bootstrap/Form'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useForm } from 'react-hook-form'
import { DeliveryOption } from './TimesheetForm'

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void,
  setDeliveryOption: (option: DeliveryOption) => void,
}

const Options = {
  download: 'download',
  email: 'email',
}

const defaultOption = Options.download

const TimesheetConfirmDialog: FunctionComponent<Props> = ({ open, setOpen, setDeliveryOption }: Props): JSX.Element => {
  const [option, setOption] = useState(defaultOption)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('downloadOrEmail', (event.target as HTMLInputElement).value)
    setOption((event.target as HTMLInputElement).value);
  }

  const onSubmit = (data: DeliveryOption) => {
    setDeliveryOption(data)
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onBlur', defaultValues: { downloadOrEmail: defaultOption, email: '' } })

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'xs'}
      >
        <Container sx={{ p: 3 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ m: 1 }} variant="standard">
              <DialogTitle sx={{ p: 0, pb: 2 }}>
                Confirm and Submit
              </DialogTitle>
              <DialogContentText sx={{ pb: 2 }}>
                Select how you would like to receive a copy of your timesheet.
              </DialogContentText>
              <RadioGroup
                aria-labelledby="select-download-or-email"
                name="downloadOrEmail"
                value={option}
                onChange={handleRadioChange}
              >
                <FormControlLabel value={Options.download} control={<Radio />} label="Download a copy" />
                <FormControlLabel value={Options.email} control={<Radio />} label="Email me a copy" />
                  {option === Options.email && <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    type="email"
                    label="Email address"
                    fullWidth
                    variant="standard"
                    sx={{ pb: 2 }}
                    placeholder="enter your email address"
                    required={option === Options.email}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...register('email', {
                      required: { value: true, message: 'Required' },
                      pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                  />}
              </RadioGroup>
              <DialogActions sx={{ pt: 4 }}>
                <Button onClick={handleClose} variant="text" >Go back</Button>
                <Button type="submit" variant="outlined" endIcon={<SendIcon />}>
                  Submit
                </Button>
              </DialogActions>
            </FormControl>
          </Form>

        </Container>
      </Dialog>
    </Fragment>
  )
}

export default TimesheetConfirmDialog