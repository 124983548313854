// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '../src/sentry'
import '../src/bugsnag'
import 'react-loading-skeleton/dist/skeleton.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '../src/bootstrap_js_files'
import ReactOnRails from 'react-on-rails'
import JobsList from '../src/components/jobs/JobsList/index'
import Timesheet from '../src/components/Timesheet'
import TimesheetForm from '../src/components/TimesheetForm'
import TimesheetUploader from '../src/components/TimesheetUploader/index'
import TimesheetPasscodeDialog from '../src/components/TimesheetPasscodeDialog'
import ContactUsForm from '../src/components/ContactUsForm/index'
import Bugsnag from '@bugsnag/js'
import * as Sentry from "@sentry/browser";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  JobsList,
  TimesheetPasscodeDialog,
  TimesheetForm,
  Timesheet,
  TimesheetUploader,
  ContactUsForm,
})

// When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  const navbar = document.getElementById("navbar");
  if (navbar) {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      navbar.classList.add("affix")
    } else {
      navbar.classList.remove("affix")
    }
  }
}
