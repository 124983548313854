import * as React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation'
import { Grid, Typography } from '@mui/material'

const primaryColor = '#54bceb'
const keySx = { pl: '2px', color: primaryColor }
const valueSx = { pl: '2px', pr: 1 }
export default function TimesheetFooter() {
  return (
    <BottomNavigation sx={{ textAlign: 'center', width: '760px' }}>
      <Grid container alignItems="center">
        <Grid xs={12}>
          <Typography sx={keySx} variant="body2" fontWeight="fontWeightBold">recruitment specialists</Typography>
        </Grid>
        <Grid xs={12} container direction="row" justifyContent="center" alignItems="center" sx={{ display: 'flex' }}>
          <Typography sx={keySx} variant="body2" fontWeight="fontWeightBold">w:</Typography>
          <Typography sx={valueSx} variant="body2" fontWeight="fontWeightRegular" color="black">www.surepeople.com.au</Typography>
          <Typography sx={keySx} variant="body2" fontWeight="fontWeightBold">e:</Typography>
          <Typography sx={valueSx} variant="body2" fontWeight="fontWeightRegular" color="black">timesheets@surepeople.net.au</Typography>
          <Typography sx={keySx} variant="body2" fontWeight="fontWeightBold">p:</Typography>
          <Typography sx={valueSx} variant="body2" fontWeight="fontWeightRegular" color="black">08 6143 4484</Typography>
          <Typography sx={keySx} variant="body2" fontWeight="fontWeightBold">a:</Typography>
          <Typography sx={valueSx} variant="body2" fontWeight="fontWeightRegular" color="black">2/752 N Lake Rd, South Lake WA 6164</Typography>
        </Grid>
      </Grid>

    </BottomNavigation>
  );
}
