import React, { FunctionComponent } from 'react'
import Typography from '@mui/material/Typography'

const TimesheetAuthority: FunctionComponent = (): JSX.Element => {
  return (
    <Typography component='div'>
      <p><strong>Check this timesheet carefully. Submitting is authority to invoice the total hours & allowance.</strong></p>
      <p>We certify that the details of attendance stated above are correct and that the work of the above named has been satisfactory. We confirm our agreement to your terms and conditions of business and undertake to pay
your account in accordance with such terms, of which we have received a copy.</p>
    </Typography>
  )
}

export default TimesheetAuthority