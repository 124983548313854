import React, { FunctionComponent, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import { TextField } from '@mui/material'

type Props = {
  csrfToken: string,
  recaptchaKey: string,
}

const messageMaxLength = 10000

const ContactUsForm: FunctionComponent<Props> = ({ csrfToken, recaptchaKey }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const recaptcha_action = 'submit_contact_us'

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setError(null)
        setSuccess(null)
      }, 5000)
      return () => clearTimeout(timer)
    }
    return
  }, [success, error])

  const grecaptchaObject = window.grecaptcha

  const submitFormWithRecaptcha = (data: any) => {
    setLoading(true)
    setError(null)
    setSuccess(null)

    grecaptchaObject.enterprise.ready(() => {
      grecaptchaObject.enterprise.execute(recaptchaKey, { action: recaptcha_action }).then((recaptchaToken: string) => {
        submitForm(recaptchaToken, data)
      })
    })
  }

  const submitForm = async (recaptchaToken: string, data: any) => {
    const url = '/email'
    const payload = {
      email: data,
      recaptcha_token: recaptchaToken,
      recaptcha_action,
    }
    const config = {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    }

    axios.post(url, payload, config)
      .then((response) => {
        setLoading(false)
        setSuccess(response.data)
        reset()
      })
      .catch((error) => {
        setLoading(false)
        setSuccess(null)
        setError(error.response.data)
      })
  }

  const submitButton = (): JSX.Element => {
    return(
      <>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<SendIcon />}
          variant="outlined"
          className="float-end"
          type="submit"
          size="large"
        >
          Send
        </LoadingButton>
        {error && <div className="alert alert-danger my-3" role="alert">{error}</div>}
        {success && <div className="alert alert-success my-3" role="alert">{success}</div>}
      </>
    )
  }

  return (
    <form id="contact-us" onSubmit={handleSubmit(submitFormWithRecaptcha)} method="POST" noValidate>
      <div className="pb-3 input-group">
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          fullWidth
          required
          error={!!errors.name}
          helperText={errors.name?.message}
          {...register('name', {
            required: {
              value: true,
              message: 'Please enter your name',
            },
            maxLength: {value: 60, message: 'Please use 60 characters or less'},
          })}
        />

      </div>
      <div className="pb-3 input-group">
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          fullWidth
          required
          error={!!errors.address_from}
          helperText={errors.address_from?.message}
          {...register('address_from', {
            required: {
              value: true,
              message: 'Please enter your email address',
            },
            pattern: {value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Please enter a valid email address'},
          })}
        />
      </div>
      <div className="pb-3 input-group">
        <TextField
          id="message"
          label="Message"
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          required
          error={!!errors.message}
          helperText={errors.message?.message}
          {...register('message', {
            required: {
              value: true,
              message: 'Please enter your message',
            },
            maxLength: {value: messageMaxLength, message: 'Max length reached'},
          })}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > messageMaxLength)
              e.target.value = e.target.value.slice(0, messageMaxLength)
         }}
        />
      </div>
      <div className="col-12">
        {submitButton()}
      </div>
    </form>
  )
}

export default ContactUsForm
