import React, { FunctionComponent, CSSProperties, useEffect, useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  Configure,
  ScrollTo,
  connectHits,
  connectPagination,
  connectRefinementList,
  connectSearchBox,
  connectStats,
  connectStateResults,
  connectPoweredBy,
} from 'react-instantsearch-dom'
import { Hit, RefinementListProvided , SearchBoxProvided, StatsProvided, StateResultsProvided } from 'react-instantsearch-core'
import Skeleton from 'react-loading-skeleton'
import useWindowSize from '../../hooks/useWindowSize.js'
import { renderHTML } from '../../../utils.js'
import AlgoliaLogo from '@/images/logos/logo-algolia.svg'
import Hero2 from '@/images/backgrounds/heroes/hero-2-7680.jpg'

type Props = {
  algoliaIndex: string
  algoliaApplicationId: string
  algoliaApiKeySearch: string
}

export const JobsList: FunctionComponent<Props> = ({ algoliaIndex, algoliaApplicationId, algoliaApiKeySearch }: Props): JSX.Element => {
  const noHits = (): JSX.Element => {
    return (
      <div className="mb-3 col-lg-12">
        <div className="border-light shadow-sm hover-animate h-100 card">
          <div className="p-4 m-4 card-body text-dark text-center">
            <i className="fa fa-search fa-3x pb-3" />
            <h5 className="card-title">
              Sorry, we can't find any matches to your query!
            </h5>
            <p className="card-text">
              Please try another query.
            </p>
          </div>
        </div>
      </div>
    )
  }

  const Hits = ({ hits }: any): JSX.Element => {
    if(hits.length === 0)
      return noHits()

      return (
      <>
        <Configure hitsPerPage={5} />
        { hits.map((hit: Hit) => (
          <div className="mb-3 col-lg-12" key={hit.objectID}>
            <a href={hit.path} className="text-decoration-none">
              <div className="border-light shadow-sm hover-animate h-100 card">
                <div className="p-4 card-body">
                  <h5 className="card-title">{renderHTML({ rawHTML: hit.title })}</h5>
                  <ul className="list-group list-group-horizontal-lg pb-2 d-flex justify-content-between flex-wrap">
                    <li className="list-group-item border-0 ps-0 bg-transparent">
                      <i className="fa fa-map-marker-alt pe-2 text-primary"></i>
                      <small>
                        <strong>
                          Location:
                        </strong>
                        {renderHTML({ rawHTML: hit.location, className: "text-dark ps-1" })}
                      </small>
                    </li>
                    <li className="list-group-item border-0 ps-0 bg-transparent">
                      <i className="fa fa-dollar-sign pe-2 text-primary"></i>
                      <small>
                        <strong>
                          Salary:
                        </strong>
                        {renderHTML({ rawHTML: hit.salary_description, className: "text-dark ps-1" })}
                      </small>
                    </li>
                    <li className="list-group-item border-0 ps-0 bg-transparent">
                      <i className="fa fa-briefcase pe-2 text-primary"></i>
                      <small>
                        <strong>
                          Type:
                        </strong>
                        <span className="text-dark ps-1">
                          {hit.work_type}
                        </span>
                      </small>
                    </li>
                    <li className="list-group-item border-0 ps-0 bg-transparent">
                      <i className="fa fa-calendar pe-2 text-primary"></i>
                      <small>
                        <strong>
                          Job added:
                        </strong>
                        <span className="text-dark ps-1">
                          {hit.humanized_published_date}
                        </span>
                      </small>
                    </li>
                  </ul>
                  <p className="card-text">
                    {renderHTML({ rawHTML: hit.summary, className: "text-dark" })}
                  </p>
                </div>
              </div>
            </a>
          </div>
        ))}
        <CustomPagination />
      </>
    )
  };
  const CustomHits = connectHits(Hits)

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }: any): JSX.Element => {
    const [paginationSize, setPaginationSize] = useState(0)
    const { break: breakpoint } = useWindowSize()
    useEffect(() => {
      // console.log('breakpoint:', breakpoint)
      switch(breakpoint) {
        case 'xs':
          setPaginationSize(5)
          break
        case 'sm':
          setPaginationSize(7)
          break
        case 'md':
          setPaginationSize(9)
          break
        default:
          setPaginationSize(9)
      }
    }, [breakpoint])
    // console.log('paginationSize', paginationSize)

    if (paginationSize === 0) return(<></>)

    const first_page = 1
    const last_page = nbPages
    const prev_page = currentRefinement - 1
    const next_page = currentRefinement + 1

    const innerSize = paginationSize - 4
    const padding = (innerSize - 1)/2
    const offset = (innerSize - (padding - 2))
    const items = Math.min(nbPages, paginationSize)
    if(nbPages === 0)
      return(<></>)

    return (
      <nav aria-label="...">
        <ul className="pagination">
          <li key='prev' className="page-item">
            <a
              href={createURL(prev_page)}
              className="page-link text-dark"
              onClick={event => {
                event.preventDefault()
                if(prev_page >= first_page)
                  refine(prev_page)
              }}
            >
              {"< Prev"}
            </a>
          </li>
          {
            new Array(items).fill(null).map((_, i) => {
              const index = i + 1
              let page: number | string
              switch(index) {
                case 1:
                  page = 1
                  break
                case 2:
                  if((currentRefinement - (padding+2)) > 1)
                    page = '...'
                  else
                    page = 2
                  break
                case items - 1:
                  if((currentRefinement + offset) <= nbPages)
                    page = '...'
                  else
                    page = nbPages - 1
                  break
                case items:
                  page = nbPages
                  break
                default:
                  if(currentRefinement < (padding + 4))
                    page = index
                  else if((currentRefinement + offset) <= nbPages)
                    // 9: 1,..,14,15,16,17,*18*,19,20,21,22,..,25 18+(9 -(4 -2)) = 25
                    // 7:    1,..,16,17,18,*19*,20,21,22,..,25    19+(7 -(3 -2)) = 25
                    // 5:       1,..,18,19,*20*,21,22,..,25       20+(5 -(2 -2)) = 25
                    // 3:          1,..,20,*21*,22,..,25          21+(3 -(1 -2)) = 25
                    // 1:             1,..,*22*,..,25             22+(1 -(0 -2)) = 25
                    page = currentRefinement + index - offset
                  else
                    page = nbPages + (index - (paginationSize))
              }

              const style = {
                fontWeight: currentRefinement === page ? 'bold' : '',
              }
              return (
                <li key={index} className="page-item">
                  <a
                    href={createURL(page)}
                    className="page-link text-dark"
                    style={style as CSSProperties}
                    onClick={event => {
                      event.preventDefault()
                      if(page !== '...')
                        refine(page)
                    }}
                  >
                    {page}
                  </a>
                </li>
              )
            })
          }
          <li key='next' className="page-item">
            <a
              href={createURL(next_page)}
              className="page-link disabled text-dark"
              onClick={event => {
                event.preventDefault()
                if(next_page <= last_page)
                  refine(next_page)
              }}
            >
              {"Next >"}
            </a>
          </li>
        </ul>
      </nav>
    )
  }

  const CustomPagination = connectPagination(Pagination)

  const PaginationSummary = ({ currentRefinement, nbPages }: any): JSX.Element => {
    return(
      <span>
        Page <strong>{currentRefinement}</strong> of <strong>{nbPages}</strong>
      </span>
    )
  }
  const CustomPaginationSummary = connectPagination(PaginationSummary)

  const Stats = ({ nbHits }: StatsProvided): JSX.Element => {
    return(
      <span>
        <strong>{nbHits.toLocaleString()}</strong> jobs -
      </span>
    )
  }

  const CustomStats = connectStats(Stats);

  const RefinementList = ({ items, refine }: RefinementListProvided): JSX.Element => {
    return(
      <div className="collapse navbar-collapse align-items-stretch pb-3" id="navbarSupportedContent">
        <div className="card border-light shadow-sm hover-animate py-0 w-100">
          <div className="p-4 card-body">
            <button className="btn-close float-end d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Close search filters"></button>
            <h5 className="card-title">Categories</h5>
            <ul className="list-group pt-3 pt-lg-0">
              {items.map((item: any) => (
                <li key={item.label} className="list-group-item d-flex border-0 px-0 bg-transparent">
                  <a
                    href="#"
                    className="me-auto text-dark pe-2"
                    style={{ fontWeight: item.isRefined ? 'bold' : '' } as CSSProperties}
                    onClick={event => {
                      event.preventDefault()
                      refine(item.value)
                    }}
                  >
                    {item.label}
                  </a>
                  <span className="badge bg-primary align-self-center text-dark">
                    {item.count}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  };

  const CustomRefinementList = connectRefinementList(RefinementList)

  const SearchBox = ({ currentRefinement, refine }: SearchBoxProvided): JSX.Element => {
    return(
      <>
      <div className="input-group input-group">
        <input
          placeholder="Search for jobs"
          type="search"
          className="form-control border-end rounded-start p-3"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
        />
        <span className="input-group-text bg-light text-dark border-0 d-none d-lg-inline-block py-3 rounded-end">
          <i className="fa fa-search p-2"></i>
        </span>
        <button className="d-lg-none bg-light p-0 btn btn-link text-decoration-none rounded-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fa fa-sliders-h p-2 text-dark" style={{fontSize: "1.5rem"}}></i>
        </button>
      </div>
    </>
    )
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const PoweredBy = ({ url, algoliaLogo }: any) => {
    return(
      <div className="d-flex">
        <span className="flex-grow-1 me-1">Search by</span>
        <a className="flex-shrink-0" href={url} aria-label="Algolia" style={{width: "80px"}}>
          <img src={algoliaLogo} alt="Search powered by Algolia"/>
        </a>
      </div>
    )
  }
  const CustomPoweredBy = connectPoweredBy(PoweredBy)

  const CustomSkeleton = (): JSX.Element => {
    return(
      <div className="card bg-white border-light shadow-sm hover-animate py-0 mb-4 w-100">
        <div className="p-4 card-body">
          <p className="mb-2">
            <Skeleton width={`100%`} />
          </p>
          <p className="mb-2 ">
            <Skeleton width={`100%`} />
          </p>
          <p className="mb-2 ">
            <Skeleton width={`75%`} />
          </p>
          <p className="mb-2 d-block">
            <Skeleton className="me-3 pt-1" circle={true} height={30} width={30} />
            <Skeleton className="" height={18} width={`40%`} />
          </p>
          <Skeleton height={50} />
        </div>
      </div>
    )
  }

  const StateResults = ({ searchResults }: StateResultsProvided): JSX.Element => {
    return(
      <section className="search-jobs">
        <div className="container">
          <div className="py-lg-5 py-0 pt-lg-0 mt-lg-n5 row">
            <div className="d-lg-none d-flex text-light pt-3 pb-2 small">
              <div className="flex-grow-1">
                <CustomStats /> <CustomPaginationSummary />
              </div>
              <div className="">
                <CustomPoweredBy algoliaLogo={AlgoliaLogo} />
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-lg-3">
              <nav className="navbar navbar-expand-lg navbar-light py-0">
                <div className="container-fluid d-block p-0">
                  <div className="text-light p-0 mb-2 d-lg-block d-none">
                    <i className="fa fa-sliders-h pe-2"></i>
                    Filters
                  </div>
                  { searchResults ? <CustomRefinementList attribute="category" /> : <CustomSkeleton /> }
                </div>
              </nav>
            </div>
            <div className="col-12 col-lg-9 text-light">
              <div className="mb-2 d-lg-flex d-none">
                <div className="flex-grow-1">
                  <CustomStats /> <CustomPaginationSummary />
                </div>
                <div className="">
                  <CustomPoweredBy algoliaLogo={AlgoliaLogo} />
                </div>
              </div>
              { searchResults ? <CustomHits /> : <CustomSkeleton /> }
            </div>
          </div>
        </div>
      </section>
    )
  }

  const CustomStateResults = connectStateResults(StateResults)

  const searchClient = algoliasearch(
    algoliaApplicationId,
    algoliaApiKeySearch
  );

  return (
    <div className="card bg-transparent text-white page-heading rounded-0 border-0 job-search">
      <div className="card-img text-center rounded-0">
        <img src={Hero2} srcSet="" sizes="100vw" alt="Search for jobs"/>
        <div className="overlay"></div>
      </div>
      <div className="">
        <InstantSearch indexName={algoliaIndex} searchClient={searchClient}>
          <ScrollTo></ScrollTo>
          <section className="pt-6 pb-5">
            <div className="container pt-lg-5 pb-4">
              <div className="row pt-3 pt-lg-5 pb-lg-5">
                <div className="mx-auto py-4">
                  <CustomSearchBox />
                </div>
              </div>
            </div>
          </section>
          <CustomStateResults />
        </InstantSearch>
      </div>
    </div>
  )
}

export default JobsList
