import React, { Fragment, FunctionComponent } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import { AlertTitle, DialogContent, DialogContentText, IconButton } from '@mui/material'
import { SuccessResponse } from './TimesheetForm'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void,
  success: SuccessResponse,
}

const TimesheetSuccessDialog: FunctionComponent<Props> = ({ open, setOpen, success }: Props): JSX.Element => {
  const handleClose = () => {
    setOpen(false)
  }

  // opens the pdfUrl in a new tab and then closes the dialog
  const downloadAndClose = () => {
    window.open(success.url, '_blank')
    setOpen(false)
  }

  if (success.url) {
    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ p: 0, mb:2 }}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {success.message}
          </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              A copy of your submitted timesheet is ready to download.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} >Cancel</Button>
            <Button onClick={downloadAndClose} autoFocus variant="outlined" startIcon={<CloudDownloadIcon />}>
              Download PDF
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ p: 3 }}
        >
          <AlertTitle>Success</AlertTitle>
          {success.message}
        </Alert>
      </Dialog>
    </Fragment>
  )
}

export default TimesheetSuccessDialog
