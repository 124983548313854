import * as Sentry from "@sentry/browser"

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "sps",
  environment: import.meta.env.VITE_RAILS_ENV,
  enabled: import.meta.env.VITE_RAILS_ENV !== "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /https:\/\/surepeople\.com\.au/, /https\:\/\/sure-people-solutions\.onrender\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});
